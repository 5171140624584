<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row justify="center" class="pt-5">
        <v-col cols="12">
          <v-expansion-panels multiple>
            <!-- General Settings -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>{{ $t('general_settings') }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-switch v-model="selectedConfiguration.use_azure_openai" :label="$t('use_azure_openai')"></v-switch>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-select
                      v-model="selectedConfiguration.model"
                      :items="filteredModels"
                      item-text="name"
                      item-value="value"
                      :label="$t('gpt_model')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <v-select
                      v-model="selectedConfiguration.language"
                      :items="languages"
                      item-text="name"
                      item-value="value"
                      :label="$t('select_main_language')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Options -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>{{ $t('options') }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Begin Options Content -->
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-checkbox 
                      dense 
                      v-model="selectedConfiguration.use_lang_detect" 
                      hide-details 
                      :label="$t('use_lang_detect')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-select 
                      :items="wordCountOptions" 
                      :label="$t('detect_from')" 
                      v-model="selectedConfiguration.lang_detect_word_count" 
                      outlined 
                      dense>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select 
                      :disabled="!selectedConfiguration.use_lang_detect" 
                      v-model="selectedConfiguration.lang_detect_model" 
                      :items="filteredModels" 
                      item-text="name" 
                      item-value="value" 
                      :label="$t('gpt_model')" 
                      outlined 
                      dense>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="4">
                    <v-checkbox 
                      dense 
                      v-model="selectedConfiguration.use_input_history" 
                      hide-details 
                      :label="$t('use_input_history')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-select 
                      :disabled="!selectedConfiguration.use_input_history" 
                      :items="inputHistoryOptions" 
                      :label="$t('number_history_inputs')" 
                      v-model="selectedConfiguration.input_history_count" 
                      outlined 
                      dense>
                    </v-select>
                  </v-col>
                </v-row>

                <v-checkbox 
                  dense 
                  v-model="selectedConfiguration.convert_markup" 
                  hide-details 
                  :label="$t('convert_markup')">
                </v-checkbox>
                <v-checkbox 
                  dense 
                  v-model="selectedConfiguration.functions_enabled" 
                  hide-details 
                  :label="$t('use_functions')">
                </v-checkbox>

                <v-row no-gutters>
                  <v-col cols="4">
                    <v-checkbox 
                      dense 
                      v-model="selectedConfiguration.rag_enabled" 
                      hide-details 
                      :label="$t('use_rag')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-select 
                      v-if="selectedConfiguration.rag_enabled" 
                      :items="ragLanguageOptions" 
                      :label="$t('language')" 
                      v-model="selectedConfiguration.rag_language" 
                      outlined 
                      dense>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="selectedConfiguration.rag_enabled">
                  <v-col cols="4">
                    <v-checkbox 
                      dense 
                      v-model="selectedConfiguration.rag_wordpress" 
                      hide-details 
                      :label="$t('use_rag_wordpress')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field 
                      v-if="selectedConfiguration.rag_wordpress" 
                      v-model="selectedConfiguration.wordpress_url" 
                      :label="$t('wordpress_domain')" 
                      outlined 
                      dense 
                      required>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="selectedConfiguration.rag_enabled">
                  <v-col cols="4">
                    <v-checkbox 
                      dense 
                      v-model="selectedConfiguration.rag_products" 
                      hide-details 
                      :label="$t('use_rag_products')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-select 
                      v-if="selectedConfiguration.rag_products" 
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" 
                      :label="$t('limit')" 
                      v-model="selectedConfiguration.rag_products_limit" 
                      outlined 
                      dense>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select 
                      v-if="selectedConfiguration.rag_products" 
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" 
                      :label="$t('max_offset')" 
                      v-model="selectedConfiguration.rag_products_max_offset" 
                      outlined 
                      dense>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox 
                      v-if="selectedConfiguration.rag_products" 
                      dense 
                      v-model="selectedConfiguration.rag_products_max_offset_random" 
                      hide-details 
                      :label="$t('max_offset_random')">
                    </v-checkbox>
                  </v-col>
                </v-row>

                <v-checkbox 
                  v-if="selectedConfiguration.rag_enabled" 
                  dense 
                  v-model="selectedConfiguration.use_only_rag" 
                  hide-details 
                  :label="$t('use_only_rag')">
                </v-checkbox>

                <v-row no-gutters>
                  <v-col cols="6">
                    <v-checkbox 
                      dense 
                      v-model="selectedConfiguration.use_gpt35_url_search" 
                      hide-details 
                      :label="$t('gpt35_url_search')">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-select 
                      :disabled="!selectedConfiguration.use_gpt35_url_search" 
                      v-model="selectedConfiguration.search_url_model" 
                      :items="filteredModels" 
                      item-text="name" 
                      item-value="value" 
                      :label="$t('gpt_model')" 
                      outlined 
                      dense>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- End Options Content -->
              </v-expansion-panel-content>
            </v-expansion-panel>


            <!-- Live chat monitoring -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>{{ $t('live_chat_monitoring') }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-checkbox dense
                      v-model="selectedConfiguration.use_live_chat_monitor"
                      hide-details
                      :label="$t('use_live_chat_monitor')">
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-checkbox dense
                      v-model="selectedConfiguration.use_alert_triggers"
                      hide-details
                      :label="$t('use_alert_triggers')">
                    </v-checkbox>
                  </v-col>
                </v-row>

                <!-- Alert triggers management section -->
                <v-row v-if="selectedConfiguration.use_alert_triggers" class="mt-3">
                  <v-col cols="12">
                    <v-text-field
                      v-model="newAlertTrigger"
                      :label="$t('add_trigger_keyword')"
                      outlined
                      dense
                      @keyup.enter="addAlertTrigger"
                      append-icon="mdi-plus"
                      @click:append="addAlertTrigger"
                      :disabled="(selectedConfiguration.alertTriggers?.length || 0) >= 10"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="selectedConfiguration.use_alert_triggers && selectedConfiguration.alertTriggers && selectedConfiguration.alertTriggers.length > 0">
                  <v-col cols="12">
                    <v-chip-group column>
                      <v-chip
                        v-for="(item, index) in selectedConfiguration.alertTriggers"
                        :key="index"
                        close
                        @click:close="removeAlertTrigger(index)"
                        class="ma-1"
                        small
                      >
                        {{ item }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>


            <!-- AI Parameters -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>{{ $t('ai_parameters') }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-subheader>{{$t('temperature')}}</v-subheader>
                <v-slider dense
                  hide-details="true"
                  v-model="selectedConfiguration.temperature"
                  max="1"
                  min="0"
                  step="0.1"
                  thumb-label
                ></v-slider>
                <v-subheader>TOP-P</v-subheader>
                <v-slider dense
                  hide-details="true"
                  v-model="selectedConfiguration.topp"
                  max="1"
                  min="0"
                  step="0.1"
                  thumb-label
                ></v-slider>
                <div class="pb-4">
                  <h5>{{$t('max_token_output')}}</h5>
                  <input type="number"
                    v-model="selectedConfiguration.max_tokens"
                    min="500"
                    max="4096"
                    @change="selectedConfiguration.max_tokens = Math.min(Math.max(selectedConfiguration.max_tokens, 500), 4096)"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Anonymize -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>{{ $t('anonymize') }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox dense
                  v-model="selectedConfiguration.anonimize_input"
                  hide-details="true"
                  :label="$t('use_anonimize_input')">
                </v-checkbox>
                <v-checkbox v-if="selectedConfiguration.anonimize_input" dense
                  v-model="selectedConfiguration.pseudonimize_input"
                  hide-details="true"
                  :label="$t('use_pseudonimize_input')">
                </v-checkbox>
                <v-row class="pt-5">
                  <v-col cols="11">
                    <v-select
                      v-model="selectedConfiguration.entitytypes_to_anonimize"
                      multiple
                      item-text="name"
                      item-value="value"
                      :items="entityTypeOptions"
                      :label="$t('select_entity_types')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3">
                    <v-btn @click="openExceptionDialog"
                      color="secondary"
                      >{{$t('exceptions')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Reporting -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>{{ $t('reporting') }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox dense
                  v-model="selectedConfiguration.use_reporting"
                  hide-details="true"
                  :label="$t('use_reporting')">
                </v-checkbox>
                <v-checkbox dense
                  v-if="selectedConfiguration?.use_reporting"
                  v-model="selectedConfiguration.use_labeling"
                  hide-details="true"
                  disabled
                  :label="$t('use_labeling')">
                </v-checkbox>

                <!-- Recipient Management Section -->
                <div v-if="selectedConfiguration.use_reporting">
                  <v-row no-gutters class="mt-3 align-center">
                    <v-col cols="12">
                      <v-text-field
                        v-model="newRecipientEmail"
                        :label="$t('add_recipient_email')"
                        outlined
                        dense
                        @keyup.enter="addRecipient"
                        :rules="[emailRule]"
                        append-icon="mdi-plus"
                        @click:append="addRecipient"
                        :disabled="(selectedConfiguration.reportingRecipients?.length || 0) >= 3"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters v-if="selectedConfiguration.reportingRecipients && selectedConfiguration.reportingRecipients.length > 0">
                    <v-col cols="12">
                      <v-chip-group
                        column
                      >
                        <v-chip
                          v-for="(email, index) in selectedConfiguration.reportingRecipients"
                          :key="index"
                          close
                          @click:close="removeRecipient(index)"
                          class="ma-1"
                          small
                        >
                          {{ email }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </div>

                <!-- Existing Post Processing Section -->
                <div>
                  <v-checkbox dense
                    v-model="selectedConfiguration.use_post_processing"
                    hide-details="true"
                    :label="$t('use_post_processing')">
                  </v-checkbox>
                  <v-select class="pt-2 pl-7" v-if="selectedConfiguration.use_post_processing"
                    v-model="selectedConfiguration.post_processing_method"
                    item-text="name"
                    item-value="value"
                    :items="post_processing_methods"
                    :label="$t('send_via')"
                    outlined
                    dense
                  ></v-select>
                  <v-text-field class="pl-7" v-if="selectedConfiguration.use_post_processing"
                    v-model="selectedConfiguration.post_processing_recipient"
                    :label="$t('recipient')"
                    outlined
                    :rules="[emailRule]"
                    dense>
                  </v-text-field>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>


            <!-- Data -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <strong>{{ $t('data') }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox dense
                  v-model="selectedConfiguration.include_manual_dataset"
                  hide-details="true"
                  :label="$t('include_manual_dataset')">
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Save and Cancel Buttons -->
          <v-row justify="center" class="pb-3">
            <v-col cols="2">
              <v-btn @click="saveChanges"
                :disabled="!hasConfigurationChanges"
                color="primary"
                >{{$t('save')}}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn @click="cancelChanges"
                :disabled="!hasConfigurationChanges"
                color="secondary"
                >{{$t('cancel')}}
              </v-btn>
            </v-col>
          </v-row>

          <!-- Exception Dialog -->
          <v-dialog v-model="isExceptionsDialogOpen" persistent max-width="40vw">
            <v-card>
              <v-card-title class="justify-center grey primary white--text">
                {{$t('exceptions')}}
              </v-card-title>
              <v-card-text class="pa-4" style="max-height: 600px; overflow-y: auto;">
                <v-simple-table dense fixed-header height="300">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th><strong>{{$t('exception')}}</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(exception, index) in exceptions" :key="index">
                        <td>
                          <input type="text" v-model="exceptions[index]">
                        </td>
                        <td>
                          <v-btn class="pl-3" icon small @click="deleteException(index)">
                            <v-icon color="primary">mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn color="primary" @click="closeExceptionDialog">{{$t('close')}}</v-btn>
                <v-btn class="pl-3" icon small @click="addException">
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { EventBus } from '../../eventBus.js';

export default {
  name: 'AiSettingsTab',
  data() {
    return {
      rules: {
        numeric: value => !isNaN(parseFloat(value)) && isFinite(value) || 'Must be numeric.'
      },
      initialConfiguration: null,
      gptModels: [
        { name: 'GPT 3.5 Turbo', value: 'gpt-3.5-turbo' },
        { name: 'GPT 4o', value: 'gpt-4o' },
        { name: 'GPT 4o mini', value: 'gpt-4o-mini' },
        { name: 'GPT 4 Turbo', value: 'gpt-4-turbo' },
        { name: 'GPT 4', value: 'gpt-4' },
        { name: 'GPT 4 1106', value: 'gpt-4-1106-preview' },
        { name: 'GPT 4 Turbo Preview', value: 'gpt-4-turbo-preview' },
        { name: 'GPT 4 0125', value: 'gpt-4-0125-preview' }
      ],
      wordCountOptions: [3, 4, 5, 6, 7, 8, 9, 10],
      inputHistoryOptions: [2, 3, 4, 5],
      ragLanguageOptions: ['Dutch', 'English'],
      isExceptionsDialogOpen: false,
      exceptions: [],
      newRecipientEmail: '',
      newAlertTrigger: '',
      emailRule: value => {
        const emailPattern = /^\S+@\S+\.\S+$/;
        return !value || emailPattern.test(value) || this.$t('invalid_email');
      },
    };
  },
  computed: {
    selectedConfiguration: {
      get() {
        return this.$store.getters.selectedConfiguration;
      },
      set(value) {
        this.$store.dispatch('updateSelectedConfiguration', value);
      }
    },
    hasConfigurationChanges() {
      const result = JSON.stringify(this.selectedConfiguration) !== JSON.stringify(this.initialConfiguration);
      if (result) {
        EventBus.$emit('disable-tabs', true);
      } else {
        EventBus.$emit('disable-tabs', false);
      }
      return result;
    },
    filteredModels() {
      // If useAzureOpenAI is true, return only the specific models
      if (this.selectedConfiguration.use_azure_openai) {
        return [
          { name: 'GPT 4o Default', value: 'gpt-4o-default' },
          { name: 'GPT 4o Mini Default', value: 'gpt-4o-mini-default' }
        ];
      }
      // Else return the full list
      return this.gptModels;
    },
    languages() {
      return [
        { name: this.$t('language.english'), value: 'en' },
        { name: this.$t('language.dutch'), value: 'nl' },
        { name: this.$t('language.german'), value: 'de' },
        { name: this.$t('language.french'), value: 'fr' },
        { name: this.$t('language.spanish'), value: 'es' },
        { name: this.$t('language.portuguese'), value: 'pt' }
      ];
    },
    entityTypeOptions() {
      return [
        { name: this.$t('person'), value: 'PER' },
        { name: this.$t('location'), value: 'LOC' },
        { name: this.$t('organization'), value: 'ORG' },
        { name: this.$t('gpe'), value: 'GPE' },
        { name: this.$t('date'), value: 'DATE' },
        { name: this.$t('time'), value: 'TIME' },
        { name: this.$t('money'), value: 'MONEY' },
        { name: this.$t('norp'), value: 'NORP' },
        { name: this.$t('email'), value: 'EMAIL' },
        { name: this.$t('phone'), value: 'PHONE' }
      ];
    },
    post_processing_methods() {
      return [
        { name: this.$t('sms'), value: 'sms' },
        { name: this.$t('email'), value: 'email' }
      ];
    }
  },
  watch: {
    selectedConfiguration: {
      handler(newValue) {
        if (newValue && newValue.use_reporting) {
          this.selectedConfiguration.use_labeling = true;

          if (!this.selectedConfiguration.labeling_instruction) {
            EventBus.$emit('show-snackbar', { type: 'WARN', message: this.$t('enter_labeling_instructions') });
          }
        } else if (newValue) {
          this.selectedConfiguration.use_labeling = false;
        }
      },
      deep: true // Ensures that nested properties are watched
    }
  },
  mounted() {
    this.initialize();
  },
  created() {
    // Reinitialize upon configuration change
    EventBus.$on('set-configuration', () => {
      this.initialize();
    });
  },
  methods: {
    async initialize() {
      // Ensure reportingRecipients is initialized
      if (!this.selectedConfiguration.reportingRecipients) {
        this.$set(this.selectedConfiguration, 'reportingRecipients', []);
      }
      this.initialConfiguration = JSON.parse(JSON.stringify(this.selectedConfiguration));
    },
    cancelChanges() {
      if (this.hasConfigurationChanges) {
        this.selectedConfiguration = JSON.parse(JSON.stringify(this.initialConfiguration));
      }
    },
    async saveChanges() {
      if (this.hasConfigurationChanges) {
        EventBus.$emit('save-configuration', this.selectedConfiguration);
      }
    },
    deleteException(index) {
      this.exceptions.splice(index, 1);
    },
    addException() {
      this.exceptions.push('');
    },
    openExceptionDialog() {
      this.exceptions = this.selectedConfiguration.exceptions || [];
      this.isExceptionsDialogOpen = true;
    },
    closeExceptionDialog() {
      this.selectedConfiguration.exceptions = this.exceptions;
      this.isExceptionsDialogOpen = false;
    },
    addRecipient() {
      if (!this.selectedConfiguration.reportingRecipients) {
        this.$set(this.selectedConfiguration, 'reportingRecipients', []);
      }
      if (this.newRecipientEmail && this.selectedConfiguration.reportingRecipients.length < 3) {
        // Email validation
        const emailPattern = /^\S+@\S+\.\S+$/;
        if (emailPattern.test(this.newRecipientEmail)) {
          this.selectedConfiguration.reportingRecipients.push(this.newRecipientEmail);
          this.newRecipientEmail = '';
        } else {
          // Show an error message
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('invalid_email') });
        }
      }
    },
    addAlertTrigger() {
      if (!this.selectedConfiguration.alertTriggers) {
        this.$set(this.selectedConfiguration, 'alertTriggers', []);
      }
      if (this.newAlertTrigger && this.selectedConfiguration.alertTriggers.length < 11) {
        this.selectedConfiguration.alertTriggers.push(this.newAlertTrigger);
        this.newAlertTrigger = '';
      }
    },

    removeRecipient(index) {
      this.selectedConfiguration.reportingRecipients.splice(index, 1);
    },

    removeAlertTrigger(index) {
      this.selectedConfiguration.alertTriggers.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.header-highlight {
  background-color: #f0f0f0; /* Light grey background color */
  padding: 10px; /* Padding around the header */
  border-radius: 5px; /* Optional: Rounded corners */
  margin-bottom: 10px; /* Optional: Space below the header */
}
.border-active {
  border: 2px solid #f0f0f0; /* Choose a color and style for your border */
  padding: 0px; /* Optional: add padding to make the border more visible */
  border-radius: 4px; /* Optional: add rounded corners if desired */
}
</style>

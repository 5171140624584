<template>
  <v-container>
    <v-row no-gutters>
      <!-- Left Section: Filters and Conversations List -->
      <v-col cols="6" md="4" class="filter-section">
        <v-card flat class="pa-4">
          <v-toolbar rounded dark dense>
            <v-spacer />
            <v-toolbar-title>
              {{ $t('Filter') }}
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3"></v-divider>

          <!-- Filters Section -->
          <v-row>
            <v-col cols="6">
              <div class="filter-group mb-4">
                <v-radio-group
                  v-model="selectedDateFilter"
                  @change="updateDateFilter"
                  class="filter-radio-group"
                >
                  <v-radio :label="$t('today')" value="today"></v-radio>
                  <v-radio :label="$t('yesterday')" value="yesterday"></v-radio>
                  <v-radio :label="$t('last_week')" value="last_week"></v-radio>
                  <v-radio :label="$t('custom')" value="custom"></v-radio>
                </v-radio-group>

                <!-- Custom Date Range -->
                <div v-if="selectedDateFilter === 'custom'">
                  <v-menu
                    ref="menuFrom"
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="$t('date_from')"
                        v-model="dateFrom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        outlined
                        dense
                        hide-details="true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tempDateFrom"
                      :max="maxDate"
                      @input="setDateFrom($event)"
                      no-title
                      show-adjacent-months
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menuTo"
                    v-model="menuTo"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="$t('date_to')"
                        v-model="dateTo"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        outlined
                        dense
                        hide-details="true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tempDateTo"
                      :max="maxDate"
                      @input="setDateTo($event)"
                      no-title
                      show-adjacent-months
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <!-- Tag Filters -->
              <v-row class="pt-4">
                <v-col style="padding:0px;"
                  v-for="(count, label) in labelCounts"
                  :key="label"
                >
                  <v-chip
                    :class="{ active: activeLabel === label }"
                    @click="toggleLabel(label)"
                    class="ma-1 small-chip"
                    color="#1d0d3e"
                    text-color="white"
                    :input-value="activeLabel === label"
                    small
                  >
                    <v-icon left v-if="activeLabel === label" color="green">mdi-check</v-icon>
                    <v-icon left v-else color="red">mdi-close</v-icon>
                    {{ label }}: {{ count }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Search Button -->
          <v-btn block class="search-button mt-4" color="primary" @click="searchConversations">
            {{ $t('search') }}
            <v-icon right>mdi-magnify</v-icon>
          </v-btn>

          <v-divider class="my-4"></v-divider>

          <!-- Conversations List -->
          <div class="conversation-list">
            <v-list dense v-if="conversationsByDate.length > 0">
              <v-list-item
                v-for="(dateGroup, index) in conversationsByDate"
                :key="index"
                class="conversation-group"
              >
                <v-list-item-content>
                  <div class="group-header mb-2" @click="toggleGroup(index)">
                    <v-icon>{{ expandedGroups[index] ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    <span>
                      {{ dateGroup.date }} ({{ dateGroup.conversations.length }}
                      <span>{{ dateGroup.conversations.length > 1 ? $t('conversations') : $t('conversation') }}</span>)
                    </span>
                  </div>

                  <div v-if="expandedGroups[index]" class="conversation-items">
                    <v-list-item
                      v-for="conversation in dateGroup.conversations"
                      :key="conversation.id"
                      @click="openConversation(conversation)"
                      :class="{ active: selectedConversation?.session_id === conversation.id }"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-caption" :class="{ active_text: selectedConversation?.session_id === conversation.id }">
                          <span v-if="conversation.type === 1" class="conversation-item">
                            <i class="mdi mdi-test-tube conversation-icon"></i> <span class="conversation-text">{{$t('test_conversation')}}</span>
                          </span>
                          <span v-if="conversation.type === 2" class="conversation-item">
                            <i class="mdi mdi-web conversation-icon"></i> <span class="conversation-text">{{$t('web_conversation')}}</span>
                          </span>
                          <span v-if="conversation.type === 5" class="conversation-item">
                            <i class="mdi mdi-whatsapp conversation-icon"></i> <span class="conversation-text">{{$t('whatsapp_conversation')}}</span>
                          </span>
                          <span v-if="conversation.type === 6" class="conversation-item">
                            <i class="mdi mdi-email conversation-icon"></i> <span class="conversation-text">{{$t('email_conversation')}}</span>
                          </span>
                          {{ formatDateTime(dateGroup.date, conversation.timeStarted) }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-caption" :class="{ active_text: selectedConversation?.session_id === conversation.id }">
                          {{ $t('number_of_messages') }}: {{ conversation.user_messages_count }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="chip-container">
                          <v-chip
                            v-for="label in conversation.labels"
                            :key="label"
                            small
                            color="grey lighten-2"
                            text-color="black"
                            class="ma-1"
                          >
                            {{ label }}
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-divider></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn v-if="isAdmin && conversationsByDate.length > 0" dense
                @click="deleteTestConversations"
              >
              Delete test conversations
            </v-btn>
            <div v-else class="empty-message">
              {{ $t('no_conversations_found') }}
            </div>
          </div>
        </v-card>
      </v-col>

      <!-- Right Section: Selected Conversation -->
      <v-col cols="6" md="8" v-if="selectedConversation && isAdmin">
        <v-card flat class="pa-4">

          <!-- Collapsible Costs and Token Info -->
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('conversation_costs') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <strong>{{ $t('input_cost') }}:</strong> €{{ selectedConversation.input_cost.toFixed(4) }}
                  </v-col>
                  <v-col cols="6">
                    <strong>{{ $t('output_cost') }}:</strong> €{{ selectedConversation.output_cost.toFixed(4) }}
                  </v-col>
                  <v-col cols="6">
                    <strong>{{ $t('input_tokens') }}:</strong> {{ selectedConversation.input_tokens }}
                  </v-col>
                  <v-col cols="6">
                    <strong>{{ $t('output_tokens') }}:</strong> {{ selectedConversation.output_tokens }}
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Conversation Details Header -->
          <v-toolbar outlined dark dense>
            <v-spacer />
            <v-toolbar-title>
              {{ $t('conversation_details') }}
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3"></v-divider>

          <!-- Message Content -->
          <v-card-text class="dialog-content">
            <div
              v-for="(message, index) in filteredMessages"
              :key="index"
              :class="getMessageClass(message)"
              class="message-container"
            >
              <!-- Sender Label -->
              <div class="message-sender">
                {{ message.role === 'user' ? $t('user') : message.role === 'assistant' ? $t('AI') : message.role === 'human_agent' ? $t('HUMAN_AGENT') : $t('system') }}
              </div>

              <!-- Collapsible System Message -->
              <template v-if="message.role === 'system' && isAdmin">
                <div class="system-message" @click="toggleSystemMessage(index)">
                  <v-icon small>{{ systemMessageState[index] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  <span>{{ $t('system_message') }}</span>
                </div>
                <div v-if="systemMessageState[index]" class="system-message-content">
                  <div v-html="message.content"></div>
                </div>
              </template>

              <!-- User Messages -->
              <template v-if="message.role === 'user'">
                <div class="message-bubble" v-html="message.raw_input || message.content"></div>
                <small class="message-time">
                  {{ formatTime(message.timestamp) }}
                </small>
              </template>
              <!-- Assistant Messages -->
               <template v-else>
                <div class="message-bubble" v-html="message.content"></div>
                <small class="message-time">
                  {{ formatTime(message.timestamp) }}
                </small>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="8" v-if="selectedConversation && !isAdmin">
        <v-card flat class="pa-4">
          <v-toolbar outlined dark dense>
            <v-spacer />
            <v-toolbar-title>
              {{ $t('conversation_details') }}
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3"></v-divider>

          <v-card-text class="dialog-content">
            <div
              v-for="(message, index) in selectedConversation.messages"
              :key="index"
              :class="getMessageClass(message)"
            >
              <!-- User/Assistant Messages -->
              <template>
                <h3 class="text-right" v-if="message.timestamp">
                  {{ formatTime(message.timestamp) }}
                </h3>
                <div v-html="message.role === 'user' ? message.raw_input || message.content : message.content"></div>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { EventBus } from "../../eventBus.js";

export default {
  name: "HistoryConversations",
  props: {
    configurationId: String,
  },
  data() {
    return {
      menuFrom: false,
      menuTo: false,
      dateFrom: null,
      tempDateFrom: null,
      dateTo: null,
      tempDateTo: null,
      maxDate: null,
      selectedDateFilter: "today",
      conversationsByDate: [],
      allConversationsByDate: [],
      selectedConversation: null,
      expandedGroups: [],
      systemMessageState: {},
      labelCounts: {},
      activeLabel: null,
    };
  },
  mounted() {
    this.updateDateFilter();
  },
  computed: {
    localDate() {
      const date = new Date();
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().substr(0, 10);
    },
    filteredMessages() {
      if (!this.selectedConversation || !this.selectedConversation.chat) return [];
      return this.selectedConversation.chat?.messages || this.selectedConversation.messages;
    },
    isAdmin() {
      return this.$store.state.userRole === "admin";
    },
  },
  methods: {
    setDateFrom(newVal) {
      if (newVal > this.dateTo) {
        this.dateTo = newVal;
      }
      this.dateFrom = newVal;
    },
    setDateTo(newVal) {
      if (newVal < this.dateFrom) {
        this.dateFrom = newVal;
      }
      this.dateTo = newVal;
    },
    updateDateFilter() {
      const today = new Date();
      if (this.selectedDateFilter === "today") {
        this.dateFrom = this.dateTo = this.localDate;
      } else if (this.selectedDateFilter === "yesterday") {
        const yesterday = new Date(today.setDate(today.getDate() - 1));
        this.dateFrom = this.dateTo = yesterday.toISOString().substr(0, 10);
      } else if (this.selectedDateFilter === "last_week") {
        const lastWeekStart = new Date(today.setDate(today.getDate() - 7));
        this.dateFrom = lastWeekStart.toISOString().substr(0, 10);
        this.dateTo = this.localDate;
      }
    },
    async searchConversations() {
      try {
        const token = localStorage.getItem("userToken");
        const params = new URLSearchParams({
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          configurationId: this.configurationId,
        }).toString();

        const response = await axios.get(
          `${this.$config.configurationServer}/chatoverview/list_sessions_for_customer?${params}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('*** response.data', response.data);
        this.conversationsByDate = response.data;
        this.allConversationsByDate = response.data;
        this.extractLabels();
        this.expandedGroups = Array(this.conversationsByDate.length).fill(false);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    },
    toggleGroup(index) {
      this.$set(this.expandedGroups, index, !this.expandedGroups[index]);
    },
    async openConversation(conversation) {
      EventBus.$emit("show-overlay");
      try {
        if (this.isAdmin) {
          this.selectedConversation = await this.getSession(conversation);
        } else {
          this.selectedConversation = await this.getSessionForCustomer(conversation);
        }
        console.log('***  this.selectedConversation:',  this.selectedConversation);
        this.systemMessageState = {};
        this.selectedConversation.chat?.messages.forEach((_, index) => {
          if (_.role === "system") this.$set(this.systemMessageState, index, false);
        });
      } catch (error) {
        EventBus.$emit("show-snackbar", {
          type: "ERROR",
          message: this.$t("error") + error,
        });
      } finally {
        EventBus.$emit("hide-overlay");
      }
    },
    async getSession(conversation) {
      const token = localStorage.getItem("userToken");
      const params = new URLSearchParams({
        session_id: conversation.id,
      }).toString();

      const response = await axios.get(
        `${this.$config.configurationServer}/chatoverview/get_session?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    async getSessionForCustomer(conversation) {
      const token = localStorage.getItem("userToken");
      const params = new URLSearchParams({
        session_id: conversation.id,
      }).toString();
      const response = await axios.get(
        `${this.$config.configurationServer}/chatoverview/get_session_for_customer?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('*** response.data: ', response.data);
      return response.data;
    },
    toggleSystemMessage(index) {
      this.$set(this.systemMessageState, index, !this.systemMessageState[index]);
    },
    getMessageClass(message) {
      return {
        "chatSession-message-user": message.role === "user",
        "chatSession-message-assistant": message.role === "assistant",
        "chatSession-message-system": message.role === "system",
        "chatSession-message-human-agent": message.role === "human_agent",
      };
    },
    formatDateTime(date, time) {
      const dateTime = new Date(`${date}T${time}Z`);
      return new Intl.DateTimeFormat(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(dateTime);
    },
    formatTime(timestamp) {
      const utcTimestamp = timestamp + "Z";
      const date = new Date(utcTimestamp);
      return date.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    extractLabels() {
      const labelCounts = {};
      this.allConversationsByDate.forEach((dateItem) => {
        dateItem.conversations.forEach((conversation) => {
          if (conversation.labels && Array.isArray(conversation.labels)) {
            conversation.labels.forEach((label) => {
              labelCounts[label] = (labelCounts[label] || 0) + 1;
            });
          }
        });
      });
      this.labelCounts = labelCounts;
    },
    toggleLabel(label) {
      if (this.activeLabel === label) {
        this.activeLabel = null;
      } else {
        this.activeLabel = label;
      }
      this.filterConversations();
    },
    filterConversations() {
      if (!this.activeLabel) {
        this.conversationsByDate = [...this.allConversationsByDate];
      } else {
        this.conversationsByDate = this.allConversationsByDate.map((dateItem) => ({
          ...dateItem,
          conversations: dateItem.conversations.filter((conversation) =>
            conversation.labels.includes(this.activeLabel)
          ),
        }));
      }
    },
    async deleteTestConversations() {
      EventBus.$emit('show-overlay');
      try {
        const token = localStorage.getItem('userToken');
        const body = {
          configuration_id: this.configurationId
        };

        await axios.post(`${this.$config.configurationServer}/chatoverview/delete_test_sessions`, body, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('delete_successful') });
      } catch (error) {
        EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });

        if (error.response && error.response.data.error) {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t(error.response.data.error) });
        } else {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('unknown_error')});
        }
      } finally {
        EventBus.$emit('hide-overlay');
      }
    },
  },
};
</script>

<style scoped>
/* General Layout */
.filter-section {
  border-right: 1px solid #ddd;
  padding-bottom: 16px;
}

/* Filters */
.filter-radio-group {
  padding-left: 8px;
}

.search-button {
  transition: all 0.3s;
}
.search-button:hover {
  background-color: #51456b !important;
  color: white;
}

/* Conversation List */
.conversation-list {
  max-height: 500px;
  overflow-y: auto;
}

/* Default styling for list items */
.conversation-item {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect */
.conversation-item:hover {
  background-color: #d4d4d4;
}

/* Active state */
.active {
  background-color: #1d0d3e;
}

.active_text {
  color: #fff !important;
}

.group-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  font-weight: bold;
  color: #424242;
}

.empty-message {
  text-align: center;
  color: #757575;
}

/* Conversation Details */
.dialog-content {
  max-height: 50vh;
  overflow-y: auto;

  border: 1px solid #b5b5b5;
  border-radius: 5px;
}

.chatSession-message-user {
  background-color: #e8f5e9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: right;
}

.chatSession-message-assistant {
  background-color: #ede7f6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.chatSession-message-system {
  background-color: #cacaca;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.system-message {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #616161;
}

.system-message-content {
  background-color: #d3d3d3;
  padding: 10px;
  margin-top: 8px;
  border-radius: 5px;
}

.chatSession-message-human-agent {
  background-color: #c2f8ff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; /* Adjust spacing between chips */
}

.text-caption {
  font-size:16px !important;
  font-family: 'CodecPro', sans-serif !important;
  line-height: 1.2rem !important;
}

</style>

<template>
  <v-container>
    <v-row>
      <!-- Left Section: Ongoing Conversations -->
      <v-col cols="6" class="ongoing-conversations">
        <v-card flat class="pa-2">
          <v-toolbar outlined dark dense>
            <v-spacer/>
            <v-toolbar-title>
              {{ $t('conversations') }}
            </v-toolbar-title>
            <v-spacer/>            
          </v-toolbar>         
          <v-divider></v-divider>
          <v-row v-if="ongoingConversations.length === 0" class="pt-5" no-gutters justify="center" >
            <v-col cols="auto">
              <span>{{ $t('no_live_conversations') }}</span>
            </v-col>
          </v-row>
          <v-list dense>
            <v-list-item
              v-for="conversation in ongoingConversations"
              :key="conversation.id"
              @click="selectConversation(conversation)"
              :class="{
                active: selectedConversation?.id === conversation.id,
                'alert-triggered': conversation.alert_triggered
              }"
              class="conversation-item compact-item"
            >
              <!-- Loading Dots for Locked Conversation -->
              <v-list-item-avatar class="compact-avatar">
                <template v-if="conversation.is_locked && conversation.is_active">
                  <div class="typing-indicator compact-dots">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </div>
                </template>
                <template v-else>
                  <!-- Differentiate AI and Human Agent handling -->
                  <v-icon style="color:#1d0d3e" v-if="!conversation.is_manual">mdi-robot</v-icon>
                  <v-icon style="color:#1d0d3e" v-else>mdi-face-agent</v-icon>
                </template>
              </v-list-item-avatar>

              <!-- Conversation Details -->
              <v-list-item-content class="conversation-details compact-details">
                <v-list-item-title>
                  <span :class="{ 'bold-text': conversation.hasNewMessage }">
                    {{ formatDateTime(conversation.date_created) }}
                  </span>        
                  <template v-if="conversation.hasNewMessage && selectedConversation?.id !== conversation.id">
                    <v-icon class="envelope-icon compact-envelope" color="darkblue">mdi-email-outline</v-icon>
                  </template>
                </v-list-item-title>
              </v-list-item-content>

              <!-- Status Chip -->
              <v-chip
                :color="conversation.is_active ? 'green' : 'grey'"
                dark
                small
                class="status-chip"
              >
                {{ conversation.is_active ? $t('ongoing') : $t('closed') }}
              </v-chip>

              <!-- Menu for Actions -->
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :style="{ color: '#484848' }"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!-- Take Over Option -->
                  <v-list-item
                    @click.stop="takeOverConversation(conversation)"
                    :disabled="conversation.is_locked || conversation.is_manual || !conversation.is_active"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-headset</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('take_over') }}</v-list-item-title>
                  </v-list-item>

                  <!-- Close Option -->
                  <v-list-item
                    @click.stop="closeConversation(conversation)"
                    :disabled="conversation.is_active"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-close</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('close_conversation') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- Right Section: Chat Messages -->
      <v-col cols="6" class="chat-interface" v-if="selectedConversation">
        <v-card flat class="pa-3">
          <v-toolbar outlined dark dense>
            <v-spacer/>
            <v-toolbar-title>
              {{ selectedConversation?.id ? $t('conversation') : $t('select_conversation') }}
            </v-toolbar-title>
            <v-spacer/>            
          </v-toolbar>                   
          <v-divider></v-divider>

          <!-- Chat Messages -->
          <div class="chat-messages">
            <v-container>
              <!-- Loop through messages -->
              <v-row
                v-for="(message, index) in selectedConversation?.messages || []"
                :key="index"
                :class="{
                  'assistant-message': message.role === 'assistant',
                  'user-message': message.role === 'user',
                  'human-agent-message': message.role === 'human_agent',
                }"
              >
                <v-col cols="12">
                  <div class="message-bubble">
                    <!-- Sender Type -->
                    <div class="message-sender">
                      {{ (message.role === 'user' ? $t('user') : message.role === 'assistant' ? $t('ai') : $t('human_agent')).toUpperCase() }}
                    </div>
                    <!-- Message Content -->
                    <div v-html="message.role === 'user' ? message.raw_input : message.content"></div>
                  </div>
                  <!-- Message Timestamp -->
                  <small class="message-time">
                    {{ formatDateTime(message.timestamp) }}
                  </small>
                </v-col>
              </v-row>

              <!-- Typing indicator -->
              <v-row v-if="selectedConversation?.is_locked && selectedConversation.is_active">
                <v-col cols="12">
                  <div class="typing-indicator">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <!-- Message Input -->
          <div v-if="selectedConversation && selectedConversation.is_manual && selectedConversation.is_active" class="message-input">
            <v-textarea
              v-model="newMessage"
              outlined
              rows="1"
              placeholder="Type your message..."
            ></v-textarea>
            <v-btn
              color="primary"
              class="ml-2"
              :disabled="!newMessage.trim()"
              @click="sendMessage"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
  import { EventBus } from '../../eventBus.js';
  
  export default {
    name: 'LiveConversations',
    props: {
      configurationId: String,
    },
    data() {
      return {
        ongoingConversations: [],
        selectedConversation: null,
        newMessage: ''
      };
    },
    created() {
      EventBus.$on('set-configuration', () => {        
        this.ongoingConversations = [];
        this.selectedConversation = null;
        this.newMessage = '';
      });
      EventBus.$on('update-live-conversation', (data) => {       
        this.handleConversationUpdate(data);
      });    
    },
    methods: {
      handleConversationUpdate(data) {       
        const { session_id, is_active, ...payload } = data;

        const existingIndex = this.ongoingConversations.findIndex(
          (conv) => conv.id === session_id
        );

        if (is_active) {
          if (existingIndex === -1) {
            const newConversation = {
              id: session_id,
              messages: [],
              hasNewMessage: false,
              is_active: true,
              ...payload,
            };
            this.ongoingConversations.push(newConversation);
          } else {
            const updatedConversation = {
              ...this.ongoingConversations[existingIndex],
              ...payload,
            };

            if (payload.messages) {
              const lastMessage = payload.messages[payload.messages.length - 1];
              if (
                lastMessage?.role === "user" &&
                this.selectedConversation?.id !== session_id
              ) {
                updatedConversation.hasNewMessage = true;
              }
            }

            this.$set(this.ongoingConversations, existingIndex, updatedConversation);

            if (this.selectedConversation?.id === session_id) {
              this.selectedConversation = { ...updatedConversation };              
            }
          }
        } else if (existingIndex !== -1) {
        // Update the conversation to inactive instead of removing it
        const updatedConversation = {
          ...this.ongoingConversations[existingIndex],
          is_active: false, // Mark as inactive
        };
        this.$set(this.ongoingConversations, existingIndex, updatedConversation);

        if (this.selectedConversation?.id === session_id) {
          this.selectedConversation = { ...updatedConversation };
        }}

        this.scrollToBottom();        
      },
      selectConversation(conversation) {          
          // Clear the `hasNewMessage` flag when a conversation is selected
          this.$set(conversation, "hasNewMessage", false);
          this.selectedConversation = conversation;
          if (!this.selectedConversation.messages) {
            this.selectedConversation.messages = [];
          }
          this.scrollToBottom();   
      },
      sendMessage() {
        if (!this.newMessage.trim()) return;
        
        EventBus.$emit('human-agent-message', {
          conversationId: this.selectedConversation.id,
          configurationId: this.selectedConversation.configuration_id,
          messageContent: this.newMessage,
        });

        this.newMessage = '';
      },
      formatDateTime(datetime) {
          if (!datetime) {
              return '';
          }
          const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          };
          return new Intl.DateTimeFormat(undefined, options).format(new Date(datetime));
      },
      takeOverConversation(conversation) {
        // Emit an event to the parent to notify about the manual takeover
        EventBus.$emit('manual-takeover', conversation);
        console.log(`Taking over conversation ID: ${conversation.id}`);
      },

      closeConversation(conversation) {
        // Remove the conversation from the array
        const index = this.ongoingConversations.findIndex(
          (c) => c.id === conversation.id
        );
        if (index !== -1) {
          this.ongoingConversations.splice(index, 1);
          console.log(`Closed conversation: ${conversation.id}`);
        }
      },

      scrollToBottom() {
        this.$nextTick(() => {
          const chatMessages = this.$el.querySelector('.chat-messages');
          if (chatMessages) {
            chatMessages.scrollTop = chatMessages.scrollHeight;
          }
        });
      }
    },
  };
  </script>
  
  <style scoped>

  .typing-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
}

.typing-indicator .dot {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #999;
  border-radius: 50%;
  animation: blink 1.4s infinite ease-in-out both;
}

.typing-indicator .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: -0.16s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0;
}

@keyframes blink {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.chat-messages {
  max-height: 50vh; /* Adjust 200px based on your toolbar, input height */
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.user-message {
  display: flex;
  justify-content: flex-end; /* Align user messages to the right */
  margin-bottom: 10px;
}

.assistant-message {
  display: flex;
  justify-content: flex-start; /* Align assistant messages to the left */
  margin-bottom: 10px;
}

.message-bubble {
  position: relative;
  max-width: 75%;
  padding: 10px 15px;
  border-radius: 12px;
  word-wrap: break-word;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-message .message-bubble {
  background-color: #e6f4ff; /* Light blue for user */
  color: #333;
  text-align: right;
  border-bottom-right-radius: 0; /* Corner styling */
}

.assistant-message .message-bubble {
  background-color: #fff; /* White for assistant */
  color: #333;
  text-align: left;
  border-bottom-left-radius: 0; /* Corner styling */
}

.human-agent-message {
  display: flex;
  justify-content: flex-start; /* Align human agent messages to the left */
  margin-bottom: 10px;
}

.human-agent-message .message-bubble {
  background-color: #ffe6cc; /* Light orange for human agent */
  color: #333;
  text-align: left;
  border-bottom-left-radius: 0; /* Corner styling */
}

/* Sender label */
.message-sender {
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  background: #1d0d3e;
  padding: 2px 5px;
  border-radius: 5px;
}

.v-list-item-title {
  font-size: 1rem; /* Larger for better readability */
  color: #555;
}

.message-time {
  font-size: 0.75rem;
  color: #888;
  margin-top: 4px;
  text-align: right;
}

  .conversation-item {
  padding: 8px 12px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.conversation-item:hover {
  background-color: #f9f9f9;
}

.conversation-details {
  white-space: normal; /* Allow text to wrap */
  overflow: visible; /* Ensure no text is cut off */
}

.v-list-item-title {
  font-size: 0.95rem;
  color: #333;
}

.v-list-item-subtitle {
  font-size: 0.85rem;
  color: #555;
}

.date-time {
  font-size: 0.85rem;
  color: #666;
}

.user-messages {
  font-size: 0.85rem;
  color: #888;
  margin-left: 8px;
}

.active {
  background-color: #cbcbcb !important;
  border-left: 4px solid #1d0d3e;
}
.locked {
  color: red;
}

.bold-text {
  font-weight: bold; 
}

.envelope-icon {
  margin-left: 8px;
  vertical-align: middle;
}

.compact-item {
  padding: 4px 8px; /* Reduce padding for list items */
  font-size: 0.9rem; /* Reduce font size */
}

.compact-avatar {
  width: 32px;
  height: 32px; /* Smaller avatar size */
}

.compact-details {
  padding-left: 8px; /* Reduce spacing between avatar and details */
}

.compact-envelope {
  margin-left: 4px; /* Minimal spacing for envelope icon */
  font-size: 16px; /* Smaller envelope icon */
  vertical-align: middle;
}

.typing-indicator.compact-dots {
  height: 12px; /* Reduce size for typing indicator */
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing-indicator.compact-dots .dot {
  width: 4px;
  height: 4px; /* Smaller dots */
  margin: 0 2px;
}

.alert-triggered {
  border-left: 4px solid red; /* Add a prominent red border */
  background-color: #ffe6e6; /* Light red background for subtle emphasis */
}

.bold-text {
  font-weight: bold;
}

.ml-2 {
  margin-left: 8px;
}

  </style>
  